var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.designersData)?_c('div',{staticClass:"row-gap"},_vm._l((Object.keys(_vm.designersData)),function(key,i){return _c('div',{key:`designer-${i}`},[_c('v-col',{staticClass:"graph pt-0 px-1 pb-3",attrs:{"cols":"12"}},[_c('v-card',{staticClass:"listcard elevation-1"},[_c('v-card-text',{staticClass:"pa-0"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex flex-wrap pl-1 py-1",attrs:{"cols":"5"}},[_c('v-col',{staticClass:"text-left text-uppercase font-weight-black pl-1 py-1",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.$t('design_completed'))+": "+_vm._s(key)+" ")]),_c('v-col',{class:{
                  'text-truncate': true,
                  'font-weight-black': true,
                  'text-center': true,
                  'text-uppercase': true,
                  caption: _vm.$vuetify.breakpoint.smAndDown
                }},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('h1',_vm._g({},on),[_vm._v(_vm._s(_vm.totals[key]))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$tc('total', 1)))])])],1)],1),_c('v-col',{class:{
                'text-truncate': true,
                'font-weight-black': true,
                'text-center': true,
                'text-uppercase': true,
                caption: _vm.$vuetify.breakpoint.smAndDown
              },attrs:{"cols":"7"}},[_c('pie-chart',{attrs:{"chart-data":_vm.designersData[key]}})],1)],1)],1)],1)],1)],1)}),0):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }