var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"listcard elevation-1 h-100"},[_c('v-card-text',{staticClass:"pa-0"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex flex-wrap pl-1 py-1",attrs:{"cols":"5"}},[_c('v-col',{staticClass:"text-left text-uppercase font-weight-black pl-1 py-1",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.$t('customer_origin'))+" ")]),_c('v-col',{class:{
            'text-truncate': true,
            'font-weight-black': true,
            'text-center': true,
            'text-uppercase': true,
            caption: _vm.$vuetify.breakpoint.smAndDown
          }},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('h1',_vm._g({},on),[_vm._v(_vm._s(_vm.customer && _vm.customer.total))])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$tc('total', 1)))])])],1)],1),_c('v-col',{class:{
          'text-truncate': true,
          'font-weight-black': true,
          'text-center': true,
          'text-uppercase': true,
          caption: _vm.$vuetify.breakpoint.smAndDown
        },attrs:{"cols":"7"}},[_c('pie-chart',{attrs:{"chart-data":_vm.customerData}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }