<template>
  <div class="row-gap" v-if="designersData">
    <div v-for="(key, i) in Object.keys(designersData)" :key="`designer-${i}`">
      <v-col cols="12" class="graph pt-0 px-1 pb-3">
        <v-card class="listcard elevation-1">
          <v-card-text class="pa-0">
            <v-row no-gutters>
              <v-col cols="5" class="d-flex flex-wrap pl-1 py-1">
                <v-col
                  cols="12"
                  class="text-left text-uppercase font-weight-black pl-1 py-1"
                >
                  {{ $t('design_completed') }}: {{ key }}
                </v-col>
                <v-col
                  :class="{
                    'text-truncate': true,
                    'font-weight-black': true,
                    'text-center': true,
                    'text-uppercase': true,
                    caption: $vuetify.breakpoint.smAndDown
                  }"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <h1 v-on="on">{{ totals[key] }}</h1>
                    </template>
                    <span>{{ $tc('total', 1) }}</span>
                  </v-tooltip>
                </v-col>
              </v-col>
              <v-col
                cols="7"
                :class="{
                  'text-truncate': true,
                  'font-weight-black': true,
                  'text-center': true,
                  'text-uppercase': true,
                  caption: $vuetify.breakpoint.smAndDown
                }"
              >
                <pie-chart :chart-data="designersData[key]" />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </div>
  </div>
</template>
<script>
import PieChart from './PieChart'
import { mapGetters } from 'vuex'

export default {
  components: {
    PieChart
  },
  data() {
    return {
      designersData: null,
      totals: {}
    }
  },
  computed: {
    ...mapGetters({
      me: 'session/me',
      company: 'company/getCompanyData'
    })
  },
  methods: {
    async getDesigners() {
      const response = await this.$api.dashboard.overview_designer({
        opt: {
          params: {
            company_id: this.company.pk
          }
        }
      })
      this.designersData = {}
      this.totals = {}
      const data = response.data
      const colors = [
        '#E46651',
        '#4dc9f6',
        '#f67019',
        '#f53794',
        '#537bc4',
        '#acc236',
        '#166a8f',
        '#00a950',
        '#58595b',
        '#8549ba'
      ]

      for (const key in data) {
        this.totals[key] = Object.values(data[key]).reduce((a, b) => a + b, 0)
        this.designersData[key] = {
          labels: Object.keys(data[key]),
          datasets: [
            {
              backgroundColor: colors,
              data: Object.values(data[key])
            }
          ]
        }
      }
    }
  },
  mounted() {
    this.getDesigners()
  }
}
</script>
