<template>
  <v-card class="listcard elevation-1">
    <v-card-text class="pa-0">
      <v-row no-gutters>
        <v-col cols="5" class="d-flex flex-wrap pl-1 py-1">
          <v-col
            cols="12"
            class="text-left text-uppercase font-weight-black pa-0"
          >
            {{ $tc('user', 1) }}
          </v-col>
          <v-col
            :class="{
              'text-truncate': true,
              'font-weight-black': true,
              'text-center': true,
              'text-uppercase': true,
              caption: $vuetify.breakpoint.smAndDown
            }"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <h1 v-on="on">{{ user && user.total }}</h1>
              </template>
              <span>{{ $tc('total', 1) }}</span>
            </v-tooltip>
          </v-col>
        </v-col>
        <v-col
          cols="7"
          :class="{
            'text-truncate': true,
            'font-weight-black': true,
            'text-center': true,
            'text-uppercase': true,
            caption: $vuetify.breakpoint.smAndDown
          }"
        >
          <pie-chart :chart-data="userData" />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import PieChart from './PieChart'
import { mapGetters } from 'vuex'

export default {
  components: {
    PieChart
  },
  data() {
    return {
      userData: null,
      user: null
    }
  },
  computed: {
    ...mapGetters({
      company: 'company/getCompanyData'
    })
  },
  methods: {
    async getUsers() {
      const response = await this.$api.dashboard.overview({
        type: 'users_count',
        opt: {
          params: {
            company_id: this.company.pk
          }
        }
      })
      this.user = response.data
      this.userData = {
        labels: [this.$tc('connected', 2), this.$tc('disconnected', 2)],
        datasets: [
          {
            backgroundColor: ['#00D8FF', '#E46651'],
            data: [this.user.logged, this.user.logout]
          }
        ]
      }
    }
  },
  mounted() {
    this.getUsers()
  }
}
</script>
