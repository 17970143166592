<template>
  <v-card class="listcard elevation-1 h-100">
    <v-card-text class="pa-0">
      <v-row no-gutters>
        <v-col cols="5" class="d-flex flex-wrap pl-1 py-1">
          <v-col
            cols="12"
            class="text-left text-uppercase font-weight-black pl-1 py-1"
          >
            {{ $t('customer_origin') }}
          </v-col>
          <v-col
            :class="{
              'text-truncate': true,
              'font-weight-black': true,
              'text-center': true,
              'text-uppercase': true,
              caption: $vuetify.breakpoint.smAndDown
            }"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <h1 v-on="on">{{ customer && customer.total }}</h1>
              </template>
              <span>{{ $tc('total', 1) }}</span>
            </v-tooltip>
          </v-col>
        </v-col>
        <v-col
          cols="7"
          :class="{
            'text-truncate': true,
            'font-weight-black': true,
            'text-center': true,
            'text-uppercase': true,
            caption: $vuetify.breakpoint.smAndDown
          }"
        >
          <pie-chart :chart-data="customerData" />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import PieChart from './PieChart'
import { mapGetters } from 'vuex'

export default {
  components: {
    PieChart
  },
  data() {
    return {
      customerData: null,
      customer: null
    }
  },
  computed: {
    ...mapGetters({
      company: 'company/getCompanyData'
    })
  },
  methods: {
    async getCustomers() {
      const colors = [
        '#E46651',
        '#4dc9f6',
        '#f67019',
        '#f53794',
        '#537bc4',
        '#acc236',
        '#166a8f',
        '#00a950',
        '#58595b',
        '#8549ba'
      ]
      const response = await this.$api.dashboard.overview({
        type: 'customer_origin_count',
        opt: {
          params: {
            company_id: this.company.pk
          }
        }
      })
      this.customer = response.data
      const labels = this.customer.amount.map(({ origin__name }) =>
        origin__name
          ? this.$te(origin__name.toLowerCase())
            ? this.$tc(origin__name.toLowerCase(), 1)
            : origin__name
          : this.$t('unknown')
      )
      const values = this.customer.amount.map(({ total }) => total)
      this.customerData = {
        labels: labels,
        datasets: [
          {
            backgroundColor: colors,
            data: values
          }
        ]
      }
    }
  },
  mounted() {
    this.getCustomers()
  }
}
</script>
